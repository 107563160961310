import React from "react";
import { Element } from "react-scroll";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/common/Hero";

import imgL from "../assets/image/jpg/dummy.jpg";

const IndexPage = () => {
  return (
    <>
      <PageWrapper>
        <Hero
          username="dummy"
          fullname="Dummy Boy"
          homegym="10th Planet San Mateo"
          pricing={<a>$100 for one student or <br/>2 students $65 each</a>}
          profile={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"}
          imgL={imgL}
          calendly={"https://calendly.com/root-tony/1h"}
        />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
